<template>
  <div class="upload">
    <p class="theme-color">个人影像</p>
    <div class="image-pic">
      <input
        type="file"
        accept="video/*"
        v-if="!isVideo"
        class="image"
        id="uploadImage"
        style="visibility: hidden"
        @change="selectFileImage($event)"
        capture="camera"
      />
      <label for="uploadImage">
        <van-image class="showimage-pic" :src="pic"></van-image>
      </label>
    </div>
    <div v-if="isVideo" @click="reTakePhoto">
      <div class="upload-tip" style="color: #1989fa">我要重拍</div>
    </div>
    <div v-else>
      <div class="upload-tip">{{ random }}</div>
      <div class="upload-tip theme-color">点击图片，拍摄3秒以内朗读以上数字的视频</div>
    </div>
    <div class="common-picture-tip theme-color">
      <span>提示：</span><br />
      <span>1.必须实时拍摄正确朗读4位数字的本人真实录像。</span><br />
      <span>2.视频长度在3秒以内。</span><br />
      <span>3.面部清晰。</span><br />
    </div>
  </div>
</template>

<script>
import { Uploader, Image } from "vant";
import { useStore } from "vuex";
import { onMounted, ref } from "vue";
import httpApi from "../../utils/httpApi";

const defaultPic =
  "https://bucket-client-jl.s3-ap-east-1.amazonaws.com/openAccount/image/1_photo.png";
const videoPic =
  "https://bucket-client-jl.s3-ap-east-1.amazonaws.com/openAccount/image/1_photo1.png";
export default {
  name: "Home",
  components: {
    [Uploader.name]: Uploader,
    [Image.name]: Image,
  },
  data() {
    return { filesrc: "" };
  },
  myFunction() {
    // console.log(event, event.duration);
  },
  methods: {
    async reTakePhoto() {
      this.isVideo = false;
      const { data: { videoCode = this.getCode() } = {} } = (await httpApi.getVideoCode()) || {};
      this.random = videoCode;
    },
    async selectFileImage(event) {
      const file = event.target.files[0];
      const audio = new Audio(URL.createObjectURL(file));

      const uploadVideo = async () => {
        if (
          typeof audio.duration === "number" &&
          Number.isFinite(audio.duration) &&
          audio.duration > 5
        ) {
          this.$toast(`视频长度过大（${audio.duration}）`);
        } else {
          const suffix = (file.name || "").split(".").pop() || "mp4";
          const n_name = `${new Date().valueOf()}${this.getCode()}_${this.random}.${suffix}`;
          const data = new FormData();
          data.append("type", "person_photo");
          data.append("file", file, n_name);
          data.append("fileName", n_name);
          const result = await httpApi.uploadVideo(data);
          if (!result) return;
          const {
            data: { code, msg = "上传失败、请重试" },
          } = result;
          if (code === 200) {
            this.pic = videoPic;
            this.isVideo = true;
            this.$toast("上传成功");
          } else {
            this.$toast(msg);
          }
        }
      };

      audio.addEventListener("loadedmetadata", () => {
        const { duration } = audio;
        if (duration) {
          uploadVideo();
        } else {
          setTimeout(uploadVideo, 100);
        }
      });
    },
    next() {
      this.$router.push({ path: "/info2" });
    },
    before() {
      this.$router.go(-1);
    },
    getCode() {
      return Math.floor((Math.random() * 100000) % 10000);
    },
  },
  setup() {
    const fileList = ref([]);
    const pic = ref("");
    const isVideo = ref(false);
    const random = ref("");
    onMounted(async () => {
      const store = useStore();
      store.commit("setTitleValue", "上传资料");
      store.commit("setStepsShow", false);
      const { data: { videoCode = this.getCode() } = {} } = (await httpApi.getVideoCode()) || {};
      random.value = videoCode;
      const result = await httpApi.getUploadImgesByType({ type: "person_photo" });
      pic.value = defaultPic;
      if (!result || !result.data || !result.data.value) return;
      if (result.data.value.startsWith("data:")) {
        pic.value = result.data.value;
      } else {
        isVideo.value = result.data.value;
        pic.value = videoPic;
      }
    });
    return {
      isVideo,
      random,
      pic,
      fileList,
    };
  },
};
</script>

<style lang="less">
.image-pic {
  width: 200px;
  height: 300px;
  margin: auto;
  position: relative;
}
.showimage-pic {
  position: absolute;
  width: 200px;
  height: 300px;
  top: 0;
  left: 0;
}
.upload-tip {
  text-align: center !important;
  color: @font-color-2;
  margin-top: @space-2;
}
.common-picture-tip {
  margin-top: 10px;
  color: @font-color-1;
}
</style>
